import { template as template_ef5335097b5741daa6f488b83c7bc0e4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ef5335097b5741daa6f488b83c7bc0e4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
