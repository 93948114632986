import { template as template_3287614520d84b048a3de3f43adceb7c } from "@ember/template-compiler";
const WelcomeHeader = template_3287614520d84b048a3de3f43adceb7c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
