import { template as template_7237567e83a34215a3e39d3e735836cc } from "@ember/template-compiler";
const FKControlMenuContainer = template_7237567e83a34215a3e39d3e735836cc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
