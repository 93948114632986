import { template as template_be050620788842e4a121ac89b6472b20 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_be050620788842e4a121ac89b6472b20(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
